/*
 * Modal
 */

// State
const state = {
    MapModal: false,
    OffersFiltersModal: false,
    OffersSearchModal: false,
    trainingModal: false,
    SimpleApplicationModal: false,
    SpontaneousApplicationModal: false
}

// Getters
const getters = {
    getModals: state => {
        return state
    },
    getModalStatusByUid: state => uid => state[uid] ? state[uid] : false
}

// Actions
const actions = {
    open(store, uid) {
        store.commit('openModal', uid)
    },
    close(store, uid) {
        store.commit('closeModal', uid)
    }
}

// Mutations
const mutations = {
    openModal(state, uid) {
        state[uid] = true
    },
    closeModal(state, uid) {
        state[uid] = false
    }
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
