import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

/*
** Pages
*/

// State
const state = {
    sets: {},
}

// Getters
const getters = {
    getSetByHandle: state => handle => state.sets[handle],
}

// Actions
const actions = {
    loadSet(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            let set = store.getters.getSetByHandle(handle)

            // If set already exists, resolve with the set value
            if(typeof set !== 'undefined') {
                resolve(set)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If set doesn't exist, load set
            } else {

                fetchApi(queries[handle])
                    .then(r => {
                        store.commit('addSet', {handle, data: r.globalSet})
                        resolve(r.globalSet)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    },
}

// Mutations
const mutations = {
    addSet(state, {handle, data}) {
        state.sets[handle] = data
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
