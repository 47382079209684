import { fetchApi } from 'src/graphql/config';
import queries from 'src/graphql/queries';
import { i18n } from 'src/i18n'

// State
const state = {
    articles: {}
};

// Getters
const getters = {
    getArticleById: state => id => state.articles[id]
};

// Actions
const actions = {
    loadArticles(store) {
        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // If articles already exists, resolve with values
            if(store.state.articles.length > 0) {
                resolve(store.state.articles)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If not, load them
            } else {

                fetchApi(queries.articlesPartial)
                    .then(data => {
                        let articles = data.entries
                        articles.forEach((article, i) => {
                            //article = parseArticle(article)
                            store.commit('addArticle', article)
                            articles[i] = article
                        })

                        resolve(articles)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});

                    });

            }
        })
    }
};

// Mutations
const mutations = {
    addArticle(state, article) {
        state.articles[article.id] = article;
    }
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
