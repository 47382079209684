<template>
    <div :class="className">
        <div class="o-loader__bg"></div>
        <div
            v-if="firstload"
            class="o-loader__first"
        >
            <div ref="strip" class="o-loader__strip"></div>
            <span ref="plus" class="o-plus">
                <span class="o-plus__bg"></span>
                <span class="o-plus__line o-plus__line--v"></span>
                <span class="o-plus__line o-plus__line--h"></span>
            </span>
        </div>
        <deco
            v-else
            :lines="3"
            :linesHorz="true"
            class="o-loader__deco"
        />
    </div>
</template>

<script>

import Deco from 'objects/Deco';

import gsap from 'gsap'

export default {
    name: 'Loader',
    components: {
        Deco,
    },
    data: () => ({
        firstload: true,
        startTime: new Date(),
        isLoading: true,
        coverDelay: 200,
    }),
    mounted() {

        // Watch load state
        this.$store.watch(() => this.$store.getters['loader/isLoading'], (loading) => {

            // Hide first loader when load is ended
            if (this.firstload && !loading) {
                this.firstloadEnd()
                return
            }

            if (loading && !this.isLoading) {
                this.loadStart()
            } else if(!loading && this.isLoading) {
                this.loadEnd()
            }
        })


    },
    computed: {
        className() {
            let classname = 'o-loader'

            if (this.firstload) {
                classname += ' o-loader--firstload'
            }

            if (this.isLoading) {
                classname += ' is-loading'
            }

            return classname
        },
    },
    methods: {
        firstloadEnd() {
            let delay = new Date()
            delay = delay - this.startTime

            // Let at least 360 degrees rotation
            delay = delay > 3000 ? 0 : (3000 - delay)

            // Delay in seconds
            delay = delay/1000

            // Remove Loader
            gsap
                .timeline({ delay })
                .to(this.$refs.strip, .4, {
                    opacity: 0,
                    ease: 'power3.in',
                })
                .to(this.$refs.plus, .4, {
                    opacity: 0,
                    scale: 0,
                    ease: 'power3.in',
                    delay: -.1,
                    onComplete: () => {
                        this.firstload = false
                        this.isLoading = false
                    }
                })
        },
        loadStart() {
            this.isLoading = true

            // Dispatch load start and load end after 200ms for minimum animation time
            this.$store.dispatch('loader/startLoad')
            setTimeout(() => {

                // Scroll to top
                window.scrollTo(0, 0)

                this.$store.dispatch('loader/endLoad')
            }, this.coverDelay);
        },
        loadEnd() {
            this.isLoading = false
        },
    },
};

</script>

<style lang="scss">

.o-loader {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 200vw;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: left 0s .4s;

    &--firstload {
        z-index: 200;
    }

    &.is-loading {
        left: 0;
        transition: left 0s 0s;

       .o-loader__bg {
            opacity: 1;
            transition: opacity .2s ease-out;
       }

        .o-deco__line {
            opacity: 1;
            transition: opacity 0s .2s;
            animation: loaderLines 2s ease-in-out .2s infinite;
        }
    }
}

.o-loader__bg,
.o-loader__first {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.o-loader__bg {
    background-color: $color-light;
    opacity: 0;
    transition: opacity .4s ease-out;
}

.o-loader__strip {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: $color-primary-light;

    transform: translate(-50vw, 0);
    transform-origin: 100% 50%;
    animation: loaderStripInX .8s $out-expo .2s forwards;

    @media (orientation: portrait) {
        width: 100%;
        height: 50%;
        transform: translate(0, 50%);
        animation: loaderStripInY .8s $out-expo .2s forwards;
    }
}

.o-loader__deco {
    --perc: 0;
    --size: 8em;
    top: calc(50% - (var(--line-width) * (2 * var(--lines) - 1))/2);
    left: calc(50% - var(--size)/2);

    .o-deco__line {
        background-color: $color-primary-light;
        transform-origin: 50% 50%;
        opacity: 0;

        @for $i from 1 through 3 {

            &:nth-child(#{$i}) {
                --line-index: #{$i};
            }
        }
    }
}


/*----------  Plus icon  ----------*/

.o-plus {
    position: absolute;
    top: calc(50% - 3.75em/2);
    left: calc(50% - 3.75em/2);
    width: 3.75em;
    height: 3.75em;
}

.o-plus__bg {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $color-primary;
    transform: scale(0);

    animation: loaderPlusBgIn 1s $in-out-quart forwards;
}

.o-plus__line {
    position: absolute;
    display: block;
    background-color: $color-light;

    transform: scale(0);
    animation: loaderPlusLineIn .5s $out-quart .5s forwards, loaderPlusRotation 2s $in-out-circ infinite 1s;

    &--h {
        top: calc(50% - .4em/2);
        left: calc(50% - 2.5em/2);
        width: 2.5em;
        height: .4em;
    }

    &--v {
        top: calc(50% - 2.5em/2);
        left: calc(50% - .4em/2);
        width: .4em;
        height: 2.5em;
    }
}

/*----------  Animation  ----------*/

@keyframes loaderPlusBgIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}



@keyframes loaderPlusLineIn {
    from {
        transform: rotate(-45deg) scale(0);
    }
    to {
        transform: rotate(0) scale(1);
    }
}

@keyframes loaderPlusRotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(90deg);
    }
}

@keyframes loaderStripInX {
    from {
        transform: translate(-50%, 0);
    }
    to {
        transform: translate(0);
    }
}

@keyframes loaderStripInY {
    from {
        transform: translate(0, 50%);
    }
    to {
        transform: translate(0);
    }
}


@keyframes loaderLines {
    0% {
        background-color: $color-primary-light;
        transform: scaleX(0) translate(calc((var(--line-index) - 2) * 100%/var(--lines)), 0);
    }
    50% {
        background-color: $color-primary;
        transform: scaleX(1) translate(0);
    }
    100% {
        background-color: $color-primary-light;
        transform: scaleX(0) translate(calc((2 - var(--line-index)) * 100%/var(--lines)), 0);
    }
}

</style>
