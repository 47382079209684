export default {
    btn: {
        findJob: 'Trouver un emploi',
        findResource: 'Trouver une ressource',
        seeOffers: 'Consulter les offres',
        seeOffersInRegion: 'Consulter les postes disponibles dans ce territoire',
        more: 'En savoir plus',
        discoverRegion: 'Découvrir ce territoire',
        submitApplication: 'Soumettre ma candidature',
        enroll: 'S’inscrire',
        startNow: 'Commencer maintenant',
        send: 'Envoyer',
        sending: 'Envoi en cours...',
        territories: 'Territoires',
        search: 'Recherche',
        filters: 'Filtres',
        showMoreJobs: 'Afficher plus de postes',
        showMoreArticles: 'Afficher plus d’articles',
        reset: 'Réinitialiser',
        hireNow: 'Recruter maintenant',
        share: 'Partager',
        applyNow: 'Postuler maintenant',
        add: 'Ajouter',
        replace: 'Remplacer',
        route: 'S’y rendre'
    },
    title: {
        latestOffers: 'Dernières offres publiées',
        about: 'À propos',
        training: 'Formation',
        nextTrainings: 'Aucune formation | Prochaine formation | Prochaines formations',
        contact: 'Contact',
        resources: 'Trouver une ressource',
        employment: 'Trouver un emploi',
        offers: 'Offres',
    },
    misc: {
        andMore: 'Et plus encore ...',
        everywhere: 'Partout',
        copyright: 'Tous droits réservés',
        partner: 'Partenaire d’ici',
        regions: 'Régions',
        copied: 'Copié !',
        publishOn: 'Publié le {date}',
    },
    aria: {
        burger: 'Ouvrir/Fermer le menu',
        closeModal: 'Fermer',
    },
    date: {
        hour: 'heure',
        day: 'jour',
        week: 'semaine',
        month: 'plus d’un mois',
    },
    job: {
        offers: 'Offres',
        results: 'résultats',
        label: 'Poste',
        place: 'Lieu(x)',
        schedule: 'Horaire',
        duration: 'Durée',
        number: 'Postes à combler',
        trades: 'Professions',
        departments: 'Départements',
        empty: 'Aucune offre d’emploi ne correspond aux critères sélectionnés',
        noFilters: 'Aucun filtre n’est disponible',
        featured: 'Offre mise en avant',
        beginOn: 'Poste à combler pour le {date}',
        soon: 'Poste à combler dès que possible',
        bfulltime: 'Temps plein',
        bparttime: 'Temps partiel',
        bcontract: 'Contrat',
        boccasional: 'Occasionnel',
        bpermanent: 'Permanent',
        bseasonal: 'Saisonnier',
        bpaidinternship: 'Stage rémunéré',
        bunpaidinternship: 'Stage non rémunéré',
        btelecommuting: 'Télétravail',
        bstudentemployment: 'Emploi étudiant',
        bday: 'Jour',
        bevening: 'Soir',
        bnight: 'Nuit',
        bweekend: 'Fin de semaine',
        boncall: 'Sur appel',
        bonshift: 'Quart de travail',
        subtitles: {
            regions: 'Régions',
            departments: 'Départements',
            benefits: 'Avantages supplémentaires',
            statuses: 'Statuts de l’emploi',
            workshifts: 'Quarts de travail',
        },
        showing: '{from} résultats sur {to} d’affichés'
    },
    article: {
        empty: 'Aucun article disponible'
    },
    form: {
        error: {
            general: 'Veuillez remplir tous les champs obligatoires.',
            phone: 'Veuillez fournir un numéro de téléphone valide et en vérifier son format (ex. 1 555 555-5555). Si l’erreur persiste, il se peut que votre numéro de téléphone soit déjà dans notre liste, veuillez utiliser la même adresse courriel que vous avez saisi lors de votre première application.'
        },
        nameBusiness: 'Nom de l’organisation',
        nameContact: 'Nom de la personne contact',
        fullName: 'Prénom et nom',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Courriel',
        trade: 'Profession',
        training: 'Formation',
        phone: 'Téléphone',
        region: 'Région',
        resume: 'CV',
        letter: 'Lettre de présentation',
        other: 'Autre',
        specify: 'Spécifier...',
        contact: 'Préférence de contact',
        contactList:[
            {
                title: 'Matin',
            },
            {
                title: 'Après-midi',
            },
            {
                title: 'Jour',
            },
            {
                title: 'Soir',
            },
            {
                title: 'N’importe quand',
            }
        ],
        about: 'Comment avez-vous entendu parler de MON+?',
        aboutList: [
            {
                title: 'Notre site internet',
            },
            {
                title: 'Un moteur de recherche',
            },
            {
                title: 'Réseaux sociaux',
            },
            {
                title: 'Une recommandation',
            },
            {
                title: 'Par l’un de nos professionnels de la santé',
            },
            {
                title: 'Une publicité',
            },
            {
                title: 'Autre',
            }
        ],
        spontaneousApplication: 'Candidature spontanée',
        message: 'Brièvement, décrivez-nous le besoin à combler',
        title: {
            formations: 'Demande d’informations'
        },
        success: {
            resources: 'Votre demande a bien été envoyée',
            application: 'Votre candidature a bien été envoyée',
            formations: 'Votre demande d’information a bien soumise. Un membre de notre équipe entrera en contact avec vous sous peu.'
        },
        optin: 'Je consens à ce que mes données soient utilisées par les membres de l’équipe de MON+ afin qu’ils améliorent leurs pratiques professionnelles.'
    }
}
