/*
** Craft GraphQL queries
*/

import transforms from './asset-transforms'

const siteID = window.__initialData__.siteID || 1


/*** Snippets ***/

// Default data
const defaultData = `
title
id
slug
`

// Default data
const urls = `
url
uri
localized {
    url
    uri
}
`

// Image
const imageData = (size=false) => {
    let data = `
        url
        title
        width
        height
    `

    let transform = transforms[size]

    if (size && typeof transform !== 'undefined') {
        data += `
            ${transform}
            mimeType
        `
    }

    return data
}

// Images
const images = (size) => {
    return `
        images {
            ${imageData(size)}
        }
    `
}

// Header
const header = `
header {
    ...on header_BlockType {
        subtitle
        heading
        intro
        partner
    }
}
`

// Lateral link
const internalLink = `
internalLink {
    ...on internalLink_BlockType {
        label
        entry {
            sectionHandle
            title
        }
    }
}
`

// SEO
const seo = `
seo {
    title
    description
}
`

// NEO Builder
const builder = `
builder {
    ... on builder_blockGlobal_BlockType {
        typeHandle
        globalSelector
    }
    ... on builder_blockCards_BlockType {
        typeHandle
        ${header}
        cards {
            ...on cards_card_BlockType {
                label
                desc
                icon {
                    url
                    iconName
                }
            }
        }
    }
    ... on builder_blockButton_BlockType {
        typeHandle
        ${internalLink}
        featured
        form: formNames
    }
    ... on builder_blockText_BlockType {
        typeHandle
        body
    }
    ... on builder_blockContent_BlockType {
        typeHandle
        ${header}
        ${images('content')}
        ${internalLink}
    }
    ... on builder_blockRegions_BlockType {
        typeHandle
        ${header}
        regionsLayout
    }
    ... on builder_blockList_BlockType {
        typeHandle
        ${header}
        listLayout
        list {
            item
        }
    }
    ... on builder_blockCandidatureForm_BlockType {
        typeHandle
        ${header}
        status: sendInBlueStatus
        listId: sendInBlueListId
    }
    ... on builder_blockRecrutementForm_BlockType {
        typeHandle
        ${header}
        status: sendInBlueStatus
        listId: sendInBlueListId
    }
    ... on builder_blockTrainingForm_BlockType {
        typeHandle
        ${header}
        status: sendInBlueStatus
        listId: sendInBlueListId
    }
}
`

// Base entry content
const entryContent = `
    ${header}
    ${images('content')}
    ${internalLink}
    ${builder}
`



/*** Globals ***/
// Main navigation
export const initial = `{
    nav: globalSet(handle: "nav", siteId: "${siteID}") {
        ... on nav_GlobalSet {
            navNodes {
                ... on navNodes_BlockType {
                    entry {
                        ${defaultData}
                        sectionHandle
                    }
                    label
                }
            }
        }
    }
    sendInBlue: globalSet(handle: "sendInBlue", siteId: "${siteID}") {
        ... on sendInBlue_GlobalSet {
            applicationListId: applicationSendInBlueListId
            resourcesListId: resourcesSendInBlueListId
            trainingListId: trainingSendInBlueListId
        }
    }
    footer: globalSet(handle: "footer", siteId: "${siteID}") {
        ... on footer_GlobalSet {
            ${images('footer')}
            desc
            socials {
                facebook
                instagram
                linkedin
            }
            footerList {
                ... on footerList_BlockType {
                    label
                    entriesList {
                        ...on entriesList_entry_BlockType {
                            item {
                                title
                                sectionHandle
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
    regions: entries(section: "region", siteId: "${siteID}") {
        ${defaultData}
        ${urls}

        ... on region_region_Entry {
            ${images('regionListing')}
            mapSimple {
                ${imageData('mapListing')}
            }
            selectedSubRegions {
                ${defaultData}
            }
        }
    }
    trainingsList: entry(section: "training", siteId: "${siteID}") {

        ... on training_training_Entry {
            trainingsList {
                ...on trainingsList_BlockType {
                    heading
                    reference
                    dateStart
                    dateEnd
                }
            }
        }
    }
}`

// Steps
export const steps = `{
    globalSet(handle: "steps", siteId: "${siteID}") {
        ... on steps_GlobalSet {
            ${header}
            stepsList {
                ... on stepsList_step_BlockType {
                    heading
                    desc
                }
            }
        }
    }
}`

// Steps
export const testimonies = `{
    globalSet(handle: "testimonies", siteId: "${siteID}") {
        ... on testimonies_GlobalSet {
            testimonies {
                ... on testimonies_testimony_BlockType {
                    fullname
                    job
                    desc
                    regions {
                        id
                    }
                    photo {
                        ${imageData('testimonyAvatar')}
                    }
                    image {
                        ${imageData('testimonyImage')}
                    }
                }
            }
        }
    }
}`

// Application
export const application = `{
    globalSet(handle: "application", siteId: "${siteID}") {
        ... on application_GlobalSet {
            heading
            desc
        }
    }
}`

// Job types
export const jobtypes = `{
    globalSet(handle: "jobTypes", siteId: "${siteID}") {
        ... on jobTypes_GlobalSet {
            heading
            desc
        }
    }
}`

// Team
export const team = `{
    globalSet(handle: "team", siteId: "${siteID}") {
        ... on team_GlobalSet {
            ${header}
            members {
                ...on members_BlockType {
                    fullname
                    job
                    phone
                    email
                    linkedin
                    photo {
                        ${imageData('team')}
                    }
                }
            }
        }
    }
}`

// Certificates list
export const certificates = `{
    globalSet(handle: "certificates", siteId: "${siteID}") {
        ... on certificates_GlobalSet {
            ${header}
            certificates {
                ...on certificates_BlockType {
                    label
                    subtitle
                    desc
                }
            }
        }
    }
}`

// Introduction
export const introduction = `{
    globalSet(handle: "introduction", siteId: "${siteID}") {
        ... on introduction_GlobalSet {
            heading
            desc
        }
    }
}`



/*** Catégories ***/

// Category group
export const categories = (group) => `{
    categories(group: "${group}", siteId: "${siteID}") {
        ${defaultData}
    }
}`



/*** Pages ***/

// Homepage
export const home = `{
    entry(section: "home", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on home_home_Entry {
            ${entryContent}
            ${seo}
        }
    }
}`

// Employment
export const employment = `{
    entry(section: "employment", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on employment_employment_Entry {
            ${entryContent}
            ${seo}
        }
    }
}`

// Resources
export const resources = `{
    entry(section: "resources", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on resources_resources_Entry {
            ${entryContent}
            ${seo}
            subtitle
            heading
            desc

            resourcesTarget {
                ...on resourcesTarget_BlockType {
                    subtitle
                    heading
                    intro
                    list {
                        ...on list_item_BlockType {
                            label
                            heading
                            desc
                            entities {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
}`

// Offers
export const offers = `{
    entry(section: "offers", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on offers_offers_Entry {
            ${header}
            ${images()}
            ${internalLink}
            ${seo}
        }
    }
}`

// About
export const about = `{
    entry(section: "about", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on about_about_Entry {
            ${entryContent}
            ${seo}
        }
    }
}`

// Contact
export const contact = `{
    entry(section: "contact", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on contact_contact_Entry {
            ${entryContent}
            ${seo}
            map {
                lat
                lng
            }
            contactDetails {
                ...on contactDetails_TableRow {
                    label
                    content
                }
            }
        }
    }
}`

// Training
export const training = `{
    entry(section: "training", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on training_training_Entry {
            ${entryContent}
            ${seo}
        }
    }
}`

// Region
export const region = (slug) => `{
    entry(section: "region", slug: "${slug}", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on region_region_Entry {
            ${header}
            ${images('headerDeco')}
            ${builder}
            mapSimple {
                ${imageData('mapSimple')}
            }
            mapFullscreen {
                ${imageData()}
            }
            selectedSubRegions {
                ${defaultData}
            }
            ${seo}
        }
    }
}`

// // Region list
// export const regionsList = `{
//     entries(section: "region", siteId: "${siteID}") {
//         ${defaultData}
//         ${urls}
//
//         ... on region_region_Entry {
//             ${images('regionListing')}
//             mapSimple {
//                 ${imageData('mapListing')}
//             }
//             selectedSubRegions {
//                 ${defaultData}
//             }
//         }
//     }
// }`

// Jobs list
export const jobs = (args) => `{
    entries(section: "job", ${args ? args + ', ' : ''} siteId: "${siteID}") {
        ${defaultData}
        dateCreated
        ${urls}

        ... on job_job_Entry {
            featured
            publication {
              ...on publication_BlockType {
                dpublication
                expirationDate
              }
            }
            sdescription {
                ...on sdescription_BlockType {
                    sdescription_fr
                    sdescription_en
                }
            }
            selectedSubRegions {
                ${defaultData}
                lft
            }
            locationsAlt
            selectedTrades {
                ${defaultData}
                lft
            }
            selectedDurations {
                ${defaultData}
                lft
            }
            selectedDepartments {
                ${defaultData}
                lft
            }
            srefnumber
            dbegin
            oschedule
            obenefits
            ostatus
            oworkshift
        }
    }
}`

// Jobs latests
export const jobsLatests = `{
    entries(section: "job", limit: 4, orderBy: "featured DESC, postDate DESC", siteId: "${siteID}") {
        ${defaultData}
        ${urls}

        ... on job_job_Entry {

            selectedSubRegions {
                ${defaultData}
                lft
            }
            locationsAlt
            srefnumber
        }
    }
}`

// Pages
export const pages = (slug) => `{
    entry(section: "pages", slug: "${slug}", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on pages_pages_Entry {
            ${header}
            ${images('headerDeco')}
            ${builder}
            ${seo}
        }
    }
}`

// Blog
export const blog = `{
    entry(section: "blog", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle

        ... on blog_blog_Entry {
            subtitle
            heading
            ${seo}
        }
    }
}`

export const articlesPartial = `{
    entries(section: "articles") {
        ${defaultData}
        ${urls}
        sectionHandle
        postDate @formatDateTime (format: "Y-m-d")
        ... on articles_articles_Entry {
            ${images('article')}
        }
    }
}`

// Articles
export const articles = (slug) => `{
    entry(section: "articles", slug: "${slug}", siteId: "${siteID}") {
        ${defaultData}
        ${urls}
        sectionHandle
        postDate @formatDateTime (format: "Y-m-d")
        datePost: postDate @formatDateTime
        dateUpdated @formatDateTime
        ... on articles_articles_Entry {
            desc
            ${images('headerDeco')}
            ${builder}
            ${seo}
        }
    }
}`


export default {
    categories,
    initial,
//    nav,
//    footer,
    steps,
//    regionsList,
    testimonies,
    application,
    certificates,
    introduction,
    jobtypes,
    team,
    home,
    employment,
    resources,
    offers,
    about,
    contact,
    training,
    region,
    jobs,
    jobsLatests,
    pages,
    blog,
    articlesPartial,
    articles
}
