export default {
    btn: {
        findJob: 'Find a job',
        findResource: 'Find a resource',
        seeOffers: 'View offers',
        seeOffersInRegion: 'View more offers in this territory',
        more: 'More',
        discoverRegion: 'Discover this territory',
        submitApplication: 'Submit my application',
        enroll: 'Suscribe now',
        startNow: 'Start now',
        send: 'Send',
        sending: 'Sending...',
        territories: 'Territories',
        search: 'Search',
        filters: 'Filters',
        showMoreJobs: 'View more jobs',
        showMoreArticles: 'Load more posts',
        reset: 'Reset',
        hireNow: 'Hire now',
        share: 'Share',
        applyNow: 'Apply now',
        add: 'Add',
        replace: 'Replace',
        route: 'Get directions'
    },
    title: {
        latestOffers: 'Latest job offers',
        about: 'About us',
        training: 'Training',
        nextTrainings: 'No Training | Next Training | Next Trainings',
        contact: 'Contact',
        resources: 'Find a resource',
        employment: 'Find a job',
        offers: 'Offers',
    },
    misc: {
        andMore: 'And even more...',
        everywhere: 'Everywhere',
        copyright: 'All rights reserved',
        partner: 'Local partner',
        regions: 'Regions',
        copied: 'Copied!',
        publishOn: 'Posted on {date}',
    },
    aria: {
        burger: 'Open/Close navigation',
        closeModal: 'Close',
    },
    date: {
        hour: 'hour',
        day: 'day',
        week: 'week',
        month: 'over a month',
    },
    job: {
        offers: 'Offers',
        results: 'results',
        label: 'Job',
        place: 'Place(s)',
        duration: 'Duration',
        schedule: 'Schedule',
        number: 'Number of available jobs',
        trades: 'Professions',
        departments: 'Departments',
        empty: 'No job offer was found',
        noFilters: 'No filter available',
        featured: 'Featured job',
        beginOn: 'Position to fill on {date}',
        soon: 'Position to fill as soon as possible',
        bfulltime: 'Full time',
        bparttime: 'Part time',
        bcontract: 'Contract',
        boccasional: 'Occasional',
        bpermanent: 'Permanent',
        bseasonal: 'Seasonal',
        bpaidinternship: 'Paid internship',
        bunpaidinternship: 'Unpaid internship',
        btelecommuting: 'Telecommuting',
        bstudentemployment: 'Student employment',
        bday: 'Day',
        bevening: 'Evening',
        bnight: 'Night',
        bweekend: 'Week-end',
        boncall: 'On call',
        bonshift: 'On shift',
        subtitles: {
            regions: 'Region(s)',
            departments: 'Department(s)',
            benefits: 'More benefits',
            statuses: 'Job statuses',
            workshifts: 'Work shifts',
        },
        showing: 'Showing {from} of {to} results'
    },
    article: {
        empty: 'No post available'
    },
    form: {
        error: {
            general: 'Please fill all required fields.',
            phone: 'Enter a valid phone number and verify its format (ex. 1 555 555-5555). If the error persists, you phone number may be already be in our list, please use the same email address you entered on your first application.'
        },
        nameBusiness: 'Business Name',
        nameContact: 'Contact Name',
        fullName: 'Full Name',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        trade: 'Profession',
        training: 'Trainning',
        phone: 'Telephone',
        region: 'Region',
        resume: 'Resume',
        letter: 'Cover letter',
        other: 'Other',
        specify: 'Specify...',
        contact: 'Contact preferences',
        contactList:[
            {
                title: 'Morning',
            },
            {
                title: 'Afternoon',
            },
            {
                title: 'Day',
            },
            {
                title: 'Evening',
            },
            {
                title: 'At any time',
            }
        ],
        about: 'How did you hear about MON+?',
        aboutList: [
            {
                title: 'Our website',
            },
            {
                title: 'A search engine',
            },
            {
                title: 'Social media',
            },
            {
                title: 'A recommendation',
            },
            {
                title: 'From one of our healthcare professionals',
            },
            {
                title: 'An ad',
            },
            {
                title: 'Other',
            }
        ],
        spontaneousApplication: 'Spontaneous application',
        message: 'Briefly describe what you need',
        title: {
            formations: 'Information request'
        },
        success: {
            resources: 'Your request was successfully sent',
            application: 'Your application was successfully sent',
            formations: 'Your request was successfully sent. A member of our team will contact you quickly.'
        },
        optin: 'I consent to the usage of my datas by members of the MON+ team to improve their professional practices.'
    }
}
