import Vue from 'vue'
import VueI18n from 'vue-i18n'

import translations from './translations'

// Define locales
export const locales = ['fr', 'en']

// Set current local
export const currentLocal = window.__initialData__.locale

// Set default local
export const defaultLocal = 'fr'

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  'fr': {
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  }
}

// Use plugin
Vue.use(VueI18n)

// Instanciate plugin
export const i18n = new VueI18n({
    locale: currentLocal || defaultLocal,
    fallbackLocale: currentLocal || defaultLocal,
    messages: translations,
    dateTimeFormats
})
