/*
** Craft asset transform sizes
** Sizes:
** xs => 0 -> 480
** sm => 480 -> 768
** lg => 768 -> 1200
** xlg => 1200 -> 1920
*/

/** Block content **/
export const content = `
    xs:     url @transform (width: 448)
    xs2x:   url @transform (width: 896)
    sm:     url @transform (width: 448)
    sm2x:   url @transform (width: 896)
    md:     url @transform (width: 475)
    lg:     url @transform (width: 584)
`

/** Header deco **/
export const headerDeco = `
    xs:     url @transform (width: 184)
    xs2x:   url @transform (width: 368)
    sm:     url @transform (width: 298)
    sm2x:   url @transform (width: 596)
    md:     url @transform (width: 264)
    md2x:   url @transform (width: 528)
    lg:     url @transform (width: 530)
    lg2x:   url @transform (width: 1060)
`

/** Map **/
export const mapSimple = `
    xs:     url @transform (width: 440)
    xs2x:   url @transform (width: 880)
    sm:     url @transform (width: 716)
    sm2x:   url @transform (width: 1432)
    md:     url @transform (width: 294)
    lg:     url @transform (width: 439)
`

export const mapListing = `
    xs:     url @transform (width: 180)
    xs2x:   url @transform (width: 360)
    sm:     url @transform (width: 303)
    sm2x:   url @transform (width: 606)
    md:     url @transform (width: 244)
    lg:     url @transform (width: 358)
`

/** Region listing **/
export const regionListing = `
    xs:     url @transform (width: 374)
    xs2x:   url @transform (width: 748)
    sm:     url @transform (width: 430)
    sm2x:   url @transform (width: 860)
    md:     url @transform (width: 362)
    lg:     url @transform (width: 538)
`

/** Team **/
export const team = `
    xs:     url @transform (width: 254, height: 254)
    xs2x:   url @transform (width: 508, height: 508)
    sm:     url @transform (width: 315, height: 315)
    sm2x:   url @transform (width: 630, height: 630)
    md:     url @transform (width: 260, height: 260)
    lg:     url @transform (width: 288, height: 288)
`

/** Article **/
export const article = `
    xs:     url @transform (width: 290, height: 208)
    xs2x:   url @transform (width: 580, height: 416)
    sm:     url @transform (width: 400, height: 320)
    sm2x:   url @transform (width: 800, height: 640)
    md:     url @transform (width: 380, height: 304)
    md2x:   url @transform (width: 760, height: 608)
    lg:     url @transform (width: 500, height: 400)
    lg2x:   url @transform (width: 1000, height: 800)
`

/** Testimony **/
export const testimonyAvatar = `
    xs:     url @transform (width: 454)
    xs2x:   url @transform (width: 908)
    sm:     url @transform (width: 390)
    sm2x:   url @transform (width: 780)
    md:     url @transform (width: 296)
    lg:     url @transform (width: 342)
`

export const testimonyImage = `
    xs:     url @transform (width: 480)
    xs2x:   url @transform (width: 960)
    sm:     url @transform (width: 768)
    sm2x:   url @transform (width: 1536)
    md:     url @transform (width: 623)
    lg:     url @transform (width: 1030)
`

/** Footer **/
export const footer = `
    xs:     url @transform (width: 283, height: 283)
    xs2x:   url @transform (width: 566, height: 566)
    sm:     url @transform (width: 335, height: 335)
    sm2x:   url @transform (width: 680, height: 680)
    md:     url @transform (width: 317, height: 317)
    lg:     url @transform (width: 420, height: 420)
`

export default {
    content,
    headerDeco,
    mapSimple,
    team,
    article,
    testimonyAvatar,
    testimonyImage,
    footer,
}
