<template>
    <header :class="className">
        <div class="l-header__inner | grid">

            <span
                class="l-header__bg"
                @click="closeNav()"
            ></span>

            <router-link
                to="/"
                title='Mon+'
                class="l-header__logo"
                exact
                aria-label="Mon+"
            >
                <icon icon="logo" />
            </router-link>

            <div class="l-header__btns | c-btn-group">
                <btn
                    tag="router-link"
                    :href="'employment'|resolver"
                    :label="$t('btn.findJob')"
                />
                <btn
                    tag="router-link"
                    :href="'resources'|resolver"
                    :label="$t('btn.findResource')"
                    modifier="bg-secondary"
                />
            </div>

            <button
                class="o-burger"
                :aria-label="$t('aria.burger')"
                @click="navIsOpen ? closeNav() : openNav()"
            >
                <span class="o-burger__inner">
                    <span class="o-burger__line"></span>
                    <span class="o-burger__line"></span>
                </span>
            </button>

            <nav class="l-nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
                <ul class="l-nav__list">
                    <li
                        class="l-nav__item"
                        itemprop="name"
                    >
                        <span class="l-nav__link l-nav__link--subnav"
                        >
                            {{ $t('btn.territories') }}
                        </span>
                        <ul class="l-subnav">
                            <li
                                v-for="(region, i) in regions"
                                :key="`region-${i}`"
                                class="l-subnav__item"
                            >
                                <router-link
                                    :to="`/${region.uri}`"
                                    :title='region.title'
                                    class="l-subnav__link"
                                    exact
                                    itemprop="url"
                                    :style="region.images.length > 0 ? `background-image: url('${region.images[0].url}')` : false"
                                >
                                    <span class="l-subnav__label">
                                        <span class="t-link">
                                            {{ region.title }}
                                        </span>
                                    </span>
                                    <icon
                                        class="l-subnav__icon"
                                        icon="arrow-right"
                                    />
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li
                        v-for="(item, i) in nav"
                        :key="`nav-item-${i}`"
                        class="l-nav__item"
                        itemprop="name"
                    >
                        <router-link
                            :to="item.path"
                            :title='item.entry.title'
                            class="l-nav__link"
                            exact
                            itemprop="url"
                        >
                            {{ item.label ? item.label : item.entry.title }}
                        </router-link>
                    </li>
                </ul>

                <btn
                    v-for="local in localesList"
                    :key="`local-${local}`"
                    class="l-nav__local"
                    :label="local"
                    modifier="border"
                    @click.native="updateLang(local)"
                />
            </nav>
        </div>
    </header>
</template>

<script>

import Icon from 'objects/Icon'
import Btn from 'components/Btn'

import { locales, currentLocal } from 'src/i18n'
import debounce from 'lodash/debounce';

export default {
    name: 'AppHeader',
    components: {
        Icon,
        Btn,
    },
    data: () => ({
        navIsOpen: false,
        currentScroll: 0,
        height: 0,
        isFixed: false,
        scrollY: 0,
        scrollUp: 0,
    }),
    mounted() {

        // Define height
        this.height = this.$el.offsetHeight

        // Add window events
        window.addEventListener('resize', this.resize = debounce(() => {
            if (this.navIsOpen) {
                this.closeNav()
            }
        }, 100));

        window.addEventListener('scroll', this.scroll)
    },
    computed: {
        className() {
            let classname = 'l-header'

            if (this.isFixed) {
                classname += ' is-fixed'
            }

            return classname
        },
        nav() {
            return this.$store.state.global.nav
        },
        regions() {
            return this.$store.state.global.regions
        },
        localesList() {
            return locales.filter(local => local !== currentLocal)
        },
        isLoading() {
            return this.$store.getters['loader/isLoading']
        },
    },
    methods: {
        openNav() {
            this.navIsOpen = true

            this.currentScroll = window.scrollY
            document.documentElement.style.overflowY = 'hidden';
        },
        closeNav() {
            this.navIsOpen = false

            // Add previous scroll
            document.documentElement.style.overflowY = 'scroll';
            window.scrollTo(0, this.currentScroll)
        },
        updateLang(locale) {

            const currentPage = this.$store.getters['global/getCurrentPage']
            let url = `${window.location.origin}/${locale}`

            if ( currentPage && currentPage.localized )
                url = currentPage.localized[0].url

            location.href = url
        },
        scroll() {
            this.isFixed = window.scrollY > 0
        }
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit('toggleNav', isOpen)
        },
        isLoading(loading) {
            if (loading && this.navIsOpen) {
                this.closeNav()
            }
        },
    },
    destroyed() {

        // Add Window events
        window.removeEventListener('resize', this.resize);
    },
};

</script>

<style lang="scss">

.l-header {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &:before {
        @include pseudo-el($bg: $color-light);
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 1px solid $color-light;
        transform: translate(0, -100%);
        transition: transform .2s $out-expo;
    }

    &.is-fixed {
        position: fixed;
        top: 0;
        left: 0;

        &:before {
            border-color: $color-primary-light;
            transform: translate(0);
            transition: border-color .3s ease-out, transform .3s $out-expo;
        }

        .l-nav__link:not(.l-nav__link--subnav):after {
            background-color: $color-primary-light;
        }
    }
}

.l-header__inner {
    display: flex;
    height: $header-height;
    justify-content: space-between;
    align-items: center;

    @media #{md("md")} {
        font-size: .9rem;
    }

    @media #{md("lg")} {
        font-size: 1rem;
    }
}

.l-header__logo {
    display: block;

    @media #{md("xs", "max")} {
        font-size: .9rem;
    }
}

.l-header__bg {
    position: fixed;
    top: 0;
    left: 200%;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    background-color: $color-dark;
    transition: left 0s .3s, opacity .3s $in-quad;

    .nav-is-open & {
        left: 0;
        opacity: .35;
        transition: left 0s 0s, opacity .4s $out-quad;
    }

    @media #{md("sm")} {
        display: none;
    }
}

.l-header__btns {

    @media #{md("md", "max")} {
        z-index: 50;
        position: fixed;
        bottom: 0;
        left: 0;
        justify-content: center;
        width: 100%;
        padding: var(--grid-gutter-half);
        background-color: $color-primary-light;
    }

    @media #{md("xs", "max")} {
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);

        .c-btn {
            justify-content: center;
            text-align: center;
        }
    }

    @media #{md("md")} {
        position: absolute;
        top: 1.45rem;
        left: calc(50% - 4rem);
        transform: translate(-50%, 0);
    }

    @media #{md("lg")} {
        left: 50%;
    }
}


/*----------  Burger button  ----------*/

.o-burger {
    display: block;
    width: 2.75em;
    height: 2.75em;
    cursor: pointer;

    @media #{md("sm")} {
        display: none;
    }
}

.o-burger__inner {
    display: block;
    width: 100%;
    height: 100%;

    &:before {
        @include pseudo-el($bg: $color-primary-light);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $border-radius;
        transition: transform .3s ease-out;
    }

    .nav-is-open & {

        &:before {
            transform: scale(1.2);
        }
    }
}

.o-burger__line {
    position: absolute;
    left: calc(50% - 1.25em/2);
    display: block;
    width: 1.25em;
    height: .1em;
    background-color: $color-secondary;
    transition: transform .3s ease-out;

    &:nth-child(1) {
        top: calc(50% - .25em);
    }

    &:nth-child(2) {
        top: calc(50% + .25em);
    }

    .nav-is-open & {

        &:nth-child(1) {
            transform: translate(0, .25em) scale(.8);
        }

        &:nth-child(2) {
            transform: translate(0, -.25em) scale(.8);
        }
    }
}


/*----------  Navigation  ----------*/


.l-nav {

    @media #{md("sm", "max")} {
        z-index: 40;
        position: fixed;
        bottom: 3.5em;
        left: 200%;
        width: 100%;
        padding: 1em var(--grid-gutter);
        color: $color-secondary;
        background-color: $color-primary-light;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        opacity: 0;
        transform: translate(0, 2em);
        transition: left 0s .3s, transform .3s $in-expo, opacity .15s .15s;

        .nav-is-open & {
            left: 0;
            opacity: 1;
            transform: translate(0);
            transition: left 0s 0s, transform .4s $out-expo;
        }
    }

    @media #{md("xs", "max")} {
        padding-bottom: 1.5em;
    }

    @media #{md("sm")} {
        display: flex;
        align-items: center;
    }
}

.l-nav__list {
    @include reset-list;
    display: flex;

    @media #{md("sm", "max")} {
        flex-direction: column;
    }
}

.l-nav__item {

    @media #{md("sm", "max")} {
        padding-top: .25em;
        padding-bottom: .25em;
    }

    @media #{md("sm")} {
        margin-right: 1em;
        margin-left: 1em;

        &:hover {

            .l-nav__link:after {
                transform: scale(1);
                transform-origin: 0 50%;
            }

            .l-subnav {
                top: $header-height;
                transition: top 0s 0s;

                &:before {
                    transform: scale(1);
                    transition: transform .8s $out-expo;
                }
            }

            .l-subnav__item {
                opacity: 1;
                transform: translate(0) scale(1);
                transition: opacity .4s ease-out .2s;

                &:after {
                    opacity: 0;
                }

                @for $i from 1 through 3 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        transition: opacity #{.6 - $i/12}s ease-out #{.2 + $i/8}s, transform #{.6 - $i/12}s $out-expo #{.2 + $i/8}s;

                        &:after {
                            transition: opacity .6s ease-out #{.2 + $i/6}s;
                        }
                    }
                }
            }
        }
    }

    @media #{md("md")} {
        margin-right: .5em;
        margin-left: .5em;
    }

    @media #{md("lg")} {
        //margin-right: 1em;
        //margin-left: 1em;
    }
}

.l-nav__link {
    z-index: 0;
    display: inline-block;
    padding-top: .25em;
    padding-bottom: .25em;
    font-weight: 700;

    &:after {
        @include pseudo-el($height: .25em, $bg: $color-primary-light);
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform .8s $out-expo;
    }

    &--subnav {
        display: block;
        cursor: pointer;
    }

    &.is-active {
        color: $color-primary;

        &:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }
    }

    &:hover {

        &:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }
    }

    @media #{md("sm", "max")} {
        font-size: 1.375em;
    }

    @media #{md("sm")} {

        &--subnav {

            &:before {
                @include pseudo-el($width: calc(100% + 2em), $height: calc(#{$header-height}/2));
                position: absolute;
                top: 50%;
                left: calc(-2em/2);
            }

            &:after {
                background-color: $color-light;
            }
        }
    }
}

.l-nav__local {
    font-size: .875em;
    border-color: $color-light;

    .c-btn__label {
        text-transform: uppercase;
        font-weight: 400;
    }

    @media #{md("sm", "max")} {
        margin-top: 1em;
        color: $color-secondary;
    }

    @media #{md("md")} {
        margin-left: .5em;
    }

    @media #{md("lg")} {
        margin-left: 1em;
    }
}

/*----------  Sub navigation  ----------*/

.l-nav,
.l-nav__list,
.l-nav__item {

    @media #{md("sm")} {
        position: static;
    }
}


.l-subnav {
    @include reset-list;

    @media #{md("sm", "max")} {
        display: inline-flex;
        flex-direction: column;
        min-width: 50%;
        margin-bottom: 1em;
    }

    @media #{md("sm")} {
        z-index: -1;
        position: absolute;
        top: -200%;
        left: 50%;
        display: flex;
        width: 100%;
        max-width: $grid-max-width;
        padding-right: var(--grid-gutter-half);
        padding-bottom: var(--grid-gutter-half);
        padding-left: var(--grid-gutter-half);
        transition: top 0s .4s;
        transform: translate(-50%, 0);


        &:before {
            @include pseudo-el($width: 100vw, $height: calc(100% + #{$header-height}), $bg: $color-primary-light);
            position: absolute;
            bottom: 0;
            left: calc(50% - 50vw);
            transform: scale(1, 0);
            transform-origin: 50% 0;
            transition: transform .4s $out-expo;
        }
    }
}

.l-subnav__item {

    @media #{md("sm", "max")} {
        margin-top: .5em;
    }

    @media #{md("sm")} {
        flex: 1 1 0;
        opacity: 0;
        transform: scale(1, .7) translate(0, -2em);
        transform-origin: 50% 0;
        transition: opacity .1s ease-in 0s, transform .1s $in-expo 0s;

        &:after {
            @include pseudo-el($bg: $color-secondary-light);
            position: absolute;
            top: 0;
            left: 0;
            border-radius: $border-radius;
            pointer-events: none;
        }

        &:not(:first-child) {
            margin-left: .25em;
        }

        &:not(:last-child) {
            margin-right: .25em;
        }
    }
}

.l-subnav__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5em .75em;
    background-color: $color-light;
    border-radius: $border-radius;

    @media #{md("sm", "max")} {
        display: flex;
        background-image: none !important;
    }

    @media #{md("sm")} {
        align-items: flex-start;
        height: 100%;
        padding: 2em 1.25em 3em;
        color: $color-light;
        background-color: $color-grey;
        background-position: 50% 50%;
        background-size: cover;

        &:before {
            @include pseudo-el($height: 50%);
            position: absolute;
            bottom: 0;
            left: 0;
            border-radius: inherit;
            @include linear-gradient(to bottom, rgba(#fff, 0), $color-dark);
        }

        &:hover {

            .t-link {
                background-size: 100% 100%;
            }
        }
    }
}

.l-subnav__label {
    display: block;
    line-height: 1.2;
    font-weight: 700;

    .t-link {
        pointer-events: none;
        background-image: linear-gradient(transparent calc(100% - .4em), rgba(#e4efe9, .8) calc(100% - .4em), rgba(#e4efe9, .8) calc(100% - .15em), transparent calc(100% - .15em));
    }

    @media #{md("sm")} {
        font-size: 1.5em;
    }

    @media #{md("md")} {
        font-size: 2.125em;
    }
}

.l-subnav__icon {
    margin-left: 2em;

    @media #{md("sm")} {
        top: -.2em;
        font-size: 1.7rem;
    }

    @media #{md("md")} {
        top: .1em;
    }
}



</style>
