import Vue from 'vue'
import { linkResolver } from 'src/router'
import { i18n } from 'src/i18n'

// Prefix url for translation
Vue.filter('resolver', function (handle, slug) {
    if (!handle) {
        return '/'
    }

    return linkResolver(handle, slug)
})

Vue.filter('monplus', function (content) {

    if (content && content !== '') {
        return content.split('MON+').join('<span class="t-uppercase">MON+</span>')
    }
})

Vue.filter('postDate', function (date, prefix = true) {

    let postDate = new Date(date)
    postDate = i18n.d(postDate, 'short')

    return prefix ? i18n.t('misc.publishOn', {date: postDate}) : postDate
})

Vue.filter('parseEmail', function (content) {
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/img
    return content.replace(emailRegex, '<a href="mailto:$1">$1</a>')
})

Vue.filter('parsePhone', function (content) {

    const phoneRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g

    return content.replace(phoneRegex, function(match, contents, offset, input_string)
        {
            return "<a href='tel:"+match.replace(/[\s-]+/g, '')+"'>"+match+"</a>";
        }
    )
})


Vue.filter('parsePhoneEmail', function (content) {

    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/img
    const phoneRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g

    content = content.replace(phoneRegex, function(match, contents, offset, input_string)
        {
            return "<a href='tel:"+match.replace(/[\s-]+/g, '')+"'>"+match+"</a>";
        }
    )

    content = content.replace(emailRegex, '<a href="mailto:$1">$1</a>')


    return content
})
