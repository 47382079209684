import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global'
import pages from './modules/pages'
import categories from './modules/categories'
import globalsets from './modules/globalsets'
import articles from './modules/articles'
import jobs from './modules/jobs'
import modal from './modules/modal'
import loader from './modules/loader'

// Vuex
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        global,
        pages,
        categories,
        globalsets,
        articles,
        jobs,
        modal,
        loader
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
