import { fetchApi } from 'src/graphql/config';
import queries from 'src/graphql/queries';
import { i18n } from 'src/i18n'

/*
** Global
*/

const parseJob = (job) => {

    //
    // Locations: Friendly label
    let locations = job.selectedSubRegions ? job.selectedSubRegions.map(region => region.title) : []

    const fullLocations = locations.join(', ')

    if (locations.length <= 2) {
        locations = locations.join(', ')
    } else {
        locations = `${locations.slice(0,2).join(', ')} <span class="o-tag">+${locations.length - 2}</span>`
    }

    //
    // Durations: Friendly label
    let durations = job.selectedDurations ? job.selectedDurations.map(d => d.title) : []

    if (durations.length === 0) {
        durations = '–'
    } else if (durations.length <= 2) {
        durations = durations.join(',<br> ')
    } else if (durations.length > 2) {
        durations = `${durations.slice(0,2).join(',<br> ')} <span class="o-tag">+${durations.length - 2}</span>`
    }

    //
    // Jobillico benefits
    const obenefits = window.__initialData__.jobillico.benefits
    let benefits

    if ( job.obenefits ) {

        benefits = job.obenefits.split(',')
        benefits = benefits.map(element => {

            let matchedElement = obenefits.find(benefit=> benefit.id === element)

            if (matchedElement.label)
                element = matchedElement.label;

            return element;
        })
    }

    //
    // Date Begin statement
    let begin = job.dbegin || null

    if ( begin ) {
        let bdate = new Date(begin)
        bdate = i18n.d(bdate, 'short')
        begin = i18n.t('job.beginOn', {date: bdate})
    } else {
        begin = i18n.tc('job.soon')
    }

    /*
    // Created date
    if(job.dateCreated) {
        const currentDate = new Date
        let dateCreated = new Date(job.dateCreated)
        const dateDiff = Math.abs(currentDate - dateCreated)
        const hourDiff = dateDiff / 36e5
        const dayDiff = dateDiff / (24*3600*1000)
        const weekDiff = dateDiff / (24*3600*1000*7)

        if (hourDiff <= 6) {
            dateCreated = hourDiff <= 1 ? `${hourDiff} ${i18n.tc('date.hour')}` : `${hourDiff} ${i18n.tc('date.hour')}s`
        } else if(hourDiff <= 12) {
            dateCreated = `12 ${i18n.tc('date.hour')}s`
        } else if(hourDiff < 24) {
            dateCreated = `1 ${i18n.tc('date.day')}`
        } else if(dayDiff < 7) {
            dateCreated = dayDiff <= 1 ? `1 ${i18n.tc('date.day')}` : `1 ${i18n.tc('date.day')}s`
        } else if(weekDiff <= 4) {
            dateCreated = weekDiff <= 1 ? `1 ${i18n.tc('date.week')}` : `1 ${i18n.tc('date.week')}s`
        } else {
            dateCreated = i18n.tc('date.month')
        }

        job.dateCreated = dateCreated
    }
    */

    return {
        ...job,
        begin,
        durations,
        locations,
        fullLocations,
        benefits,
    }
}

// State
const state = {
    jobs: {},
    latests: [],
};

// Getters
const getters = {
    getJobById: state => id => state.jobs[id],
    getLatestJobs: state => state.latests
};

// Actions
const actions = {
    loadJobs(store, args) {
        return new Promise((resolve, reject) => {

            // Define vars from arguments
            const query = args && typeof args.query !== undefined ? args.query : ''
            const loader = args && typeof args.loader !== undefined ? args.loader : true

            // Start load
            if (loader) {
                store.dispatch('loader/startLoad', null, {root: true});
            }

            fetchApi(queries.jobs(query))
                .then(data => {
                    let jobs = data.entries
                    jobs.forEach((job, i) => {
                        job = parseJob(job)
                        store.commit('addJob', job)
                        jobs[i] = job
                    })

                    resolve(jobs)

                    // End load
                    if (loader) {
                        store.dispatch('loader/endLoad', null, {root: true});
                    }
                })
                .catch(e => {
                    reject({
                        code: 400,
                        message: 'Something went wrong'
                    })
                    console.error(e) // eslint-disable-line
                })
        })
    },
    loadLatests(store) {
        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // If latest jobs already exists, resolve with the set value
            if(store.state.latests.length > 0) {
                resolve(store.state.latests)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If latest job doesn't exist, load it
            } else {

                fetchApi(queries.jobsLatests)
                    .then(data => {
                        let jobs = data.entries
                        jobs.forEach((job, i) => {
                            job = parseJob(job)
                            store.commit('addLatestJob', job)
                            jobs[i] = job
                        })

                        resolve(jobs)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
                    .catch(e => {
                        reject({
                            code: 400,
                            message: 'Something went wrong'
                        })
                        console.error(e) // eslint-disable-line
                    })
            }
        })
    },
};

// Mutations
const mutations = {
    addJob(state, job) {
        state.jobs[job.id] = job;
    },
    addLatestJob(state, job) {
        state.latests.push(job);
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
