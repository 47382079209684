<template>
    <div
        :class="className"
        ref="container"
    >
        <loader />

        <app-header @toggleNav="toggleNav"/>

        <main class="l-main" ref="main">
            <router-view v-if="currentPage" :page="currentPage" />
        </main>

        <app-footer />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';

import Loader from 'objects/Loader';

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppFooter,
        Loader,
    },
    data: () => ({
        navIsOpen: false,
    }),
    computed: {
        className() {
            let classname = 'l-wrapper__inner'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += ' nav-is-open'
            }

            return classname
        },
        currentPage() {
            return this.$store.getters['global/getCurrentPage']
        },
        currentPageName() {
            return this.$store.getters['global/getCurrentPageName']
        }
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen
        },
    },
    watch: {
        $route(to) {
            this.$store.dispatch('global/updatePageName', to.name)
        },
    },
}

</script>
