<template>
    <footer class="l-footer">
        <div class="grid">
            <div class="grid__row u-jc-between">
                <div class="grid__col--12 grid__col--6@sm grid__col--5@md">
                    <div class="l-footer__images">
                        <asset
                            v-for="(image, i) in data.images"
                            :key="`footer-image-${i}`"
                            v-bind="image"
                            :cover="true"
                            :sizes="`60w, (min-width: 480px) 45w, (min-width: 768px) 32w, (min-width: 992px) 30w, (min-width: 1460px) 388px`"
                        />
                        <deco :lines="12" />
                        <deco :dots="true" />
                        <deco :plus="true" />
                    </div>
                </div>
                <div class="grid__col--12 grid__col--6@sm grid__col--5@md">

                    <div class="o-sitemap">
                        <div
                            v-for="(item, i) in data.footerList"
                            :key="`sitemap-item-${i}`"
                            class="o-sitemap__item"
                        >
                            <h4 class="o-sitemap__title">{{ item.label }}</h4>
                            <ul class="o-sitemap__list">

                                <li
                                    v-if="i === 0"
                                    v-for="(region, j) in regions"
                                    :key="`sitemap-region-${j}`"
                                >
                                    <router-link
                                        class="t-t4 | t-link"
                                        :to="`/${region.uri}`"
                                    >
                                        {{ region.title }}
                                    </router-link>
                                </li>
                                <li
                                    v-for="entry in item.entriesList"
                                    :key="`sitemap-entry-${entry.item[0].sectionHandle}`"
                                >
                                    <router-link
                                        class="t-t4 | t-link"
                                        :to="entry.item[0].sectionHandle|resolver(entry.item[0].slug)"
                                    >
                                        {{ localizeEntryTitle(entry.item[0]) }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <ul class="o-social">
                        <li
                            v-for="(social, i) in socials"
                            :key="`social-${i}`"
                            class="o-social__item"
                        >
                            <a
                                :href="social.url"
                                :title="social.icon"
                                target="_blank"
                                rel="noopener"
                                class="o-social__link"
                            >
                                <icon
                                    :icon="social.icon"
                                    class="o-social__icon"
                                />
                            </a>
                        </li>
                    </ul>

                    <div class="c-btn-group">
                        <btn
                            tag="router-link"
                            :href="'employment'|resolver"
                            :label="$t('btn.findJob')"
                        />
                        <btn
                            tag="router-link"
                            :href="'resources'|resolver"
                            :label="$t('btn.findResource')"
                            modifier="bg-secondary"
                        />
                    </div>
                </div>
            </div>

            <div class="l-footer__bottom | t-small">
                <span class="l-footer__copy">&copy; Multi Options Nursing {{ year }}, {{ $t('misc.copyright') }}</span>
                <div
                    v-if="data.desc"
                    class="l-footer__desc"
                    v-html="data.desc"
                ></div>
            </div>
        </div>
    </footer>
</template>

<script>

import Icon from 'objects/Icon';
import Deco from 'objects/Deco';
import Asset from 'objects/Asset';
import Btn from 'components/Btn';

export default {
    name: 'AppFooter',
    components: {
        Icon,
        Deco,
        Asset,
        Btn,
    },
    computed: {
        data() {
            return this.$store.state.global.footer
        },
        socials() {

            if (!this.data.socials) {
                return false
            }

            const socials = []
            const data = this.data.socials[0]

            if (data.facebook) {
                socials.push({
                    icon: 'facebook',
                    url: data.facebook
                })
            }

            if (data.instagram) {
                socials.push({
                    icon: 'instagram',
                    url: data.instagram
                })
            }

            if (data.linkedin) {
                socials.push({
                    icon: 'linkedin',
                    url: data.linkedin
                })
            }

            return socials
        },
        regions() {
            return this.$store.state.global.regions
        },
        year: () => new Date().getFullYear(),
    },
    methods: {
        localizeEntryTitle(entry) {

            let _localize = this.$t('title.' + entry.sectionHandle)

            if ( !_localize && _localize === '')
                _localize = entry.title

            return _localize
        }
    }
};

</script>

<style lang="scss">

.l-footer {
    margin-top: 10vh;

    @media #{md("md", "max")} {
        padding-bottom: 4em;
    }

    @media #{md("xs", "max")} {
        padding-bottom: 5em;
    }

    @media #{md("md", "max")} {

        .c-btn-group {
            display: none;
        }
    }
}

.l-footer__copy {
    min-width: 14em;
}

.l-footer__images {
    padding-bottom: 2em;

    .o-asset {
        width: 70%;
        height: 0;
        padding-top: 70%;
        border-radius: 50%;
        overflow: hidden;

        &:nth-child(1) {
            z-index: 2;
        }

        &:nth-child(2) {
            z-index: 1;
            margin-top: -40%;
            margin-left: calc(100% - 70%);
        }
    }

    .o-deco {

        &--dots {
            --size: 70%;

            bottom: 0;
            left: 15%;
        }

        &--lines {
            --color: #{$color-primary-light};
            --size: 50%;

            top: 0;
            right: 5%;
        }

        &--plus {
            z-index: 2;
            position: absolute;
            top: calc(70% - var(--size)/2 - 2em);
            left: calc(65%/2 - var(--size)/2);
        }
    }

    @media #{md("sm", "max")} {
        width: calc(2/3 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("xs", "max")} {
        width: calc(11/12 * 100%);
    }
}

.l-footer__bottom {
    margin-top: 1em;
    margin-bottom: 1em;

    @media #{md("xs", "max")} {
        flex-direction: column;
    }

    @media #{md("xs")} {
        display: flex;
        justify-content: space-between;
    }

    @media #{md("sm")} {
        display: grid;
        grid-column-gap: var(--grid-gutter);
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-end;
        margin-top: 5vh;
    }

    @media #{md("md")} {
    }
}

.l-footer__desc {
    display: inline-block;

    @media #{md("xs", "max")} {
        margin-top: 1em;
    }

    @media #{md("md")} {
        margin-left: calc(1/6 * 100%);
    }
}



/*----------  Sitemap  ----------*/

.o-sitemap {
    display: grid;
    grid-gap: var(--grid-gutter);

    @media #{md("sm", "max")} {
        margin-top: 5vh;
    }

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr)
    }
}

.o-sitemap__item {}

.o-sitemap__title {
    display: block;
    margin-bottom: .5em;
    font-size: 1em;
    font-weight: 400;
    color: $color-secondary;
}

.o-sitemap__list {
    @include reset-list;

    li {
        margin-top: .25em;
        margin-bottom: .25em;
    }
}



/*----------  Social list  ----------*/

.o-social {
    @include reset-list;

    display: flex;
    margin-top: 5%;
    margin-bottom: 7.5%;

    @media #{md("sm")} {
        margin-top: 10%;
        margin-bottom: 15%;
    }
}

.o-social__item {
}

.o-social__link {
    display: block;

    &:hover {

        .o-social__icon {
            color: $color-primary;
            transform: scale(1.1);
            transition: all .3s ease-out;
        }
    }
}

.o-social__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    height: 2.75em;
    transition: all .2s ease-in;
}


</style>
