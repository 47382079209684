import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

/*
** Pages
*/

// State
const state = {
    pages: [],
}

// Getters
const getters = {
    getPageById: state => id => state.pages.find(page => page.id === id),
    getPageByHandle: state => section => state.pages.find(page => page.sectionHandle === section),
    getPageBySlug: state => slug => state.pages.find(page => page.slug === slug),
    getPagesCount: state => state.pages.length,
}

// Actions
const actions = {
    loadSingle(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            let page = store.getters.getPageByHandle(handle)

            // If page already exists, resolve with the page value
            if(typeof page !== 'undefined') {
                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {

                fetchApi(queries[handle])
                    .then(r => {

                        if (r && r.entry) {
                            store.commit('addPage', r.entry)
                            resolve(r.entry)
                        } else {
                            reject({code: 404, message: 'Page not found.'})
                        }

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    },
    loadEntry(store, {handle, slug}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            let page = store.getters.getPageBySlug(slug)

            // If page already exists, resolve with the page value
            if(typeof page !== 'undefined') {
                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {
                fetchApi(queries[handle](slug))

                    .then(r => {

                        if (r && r.entry) {
                            store.commit('addPage', r.entry)
                            resolve(r.entry)
                        } else {
                            reject({code: 404, message: 'Entry not found.'})
                        }

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    }
}

// Mutations
const mutations = {
    addPage(state, page) {
        state.pages.push(page)
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
