<template>
    <div
        v-if="url"
        :class="className"
        :style="styles"
        v-view="animate ? parallax : false"
    >
        <span
            v-if="cover || contain"
            class="o-asset__bg"
            :style="bgStyles"
        ></span>
        <picture
            v-else-if="srcset !== false"
            class="o-asset__picture"
        >
            <source
                :media="`(max-width: 479px)`"
                :srcset="`${xs}, ${xs2x} 2x`"
                :type="mimeType"
            >
            <source
                :media="`(max-width: 767px)`"
                :srcset="`${sm}, ${sm2x} 2x`"
                :type="mimeType"
            >
            <source
                :media="`(max-width: 991px)`"
                :srcset="`${md}, ${md2x} 2x`"
                :type="mimeType"
            >
            <source
                :media="`(min-width: 992px)`"
                :srcset="`${lg}, ${lg2x} 2x`"
                :type="mimeType"
            >
            <img
                :src="url"
                :alt="title"
                :width="width"
                :height="height"
                :sizes="sizes"
                loading="lazy"
                class="o-asset__img"
            />
        </picture>
        <img
            v-else
            :src="url"
            :alt="title"
            :width="width"
            :height="height"
            loading="lazy"
            class="o-asset__img"
        />
    </div>
</template>

<script>

import gsap from 'gsap'

export default {
    name: 'Asset',
    props: {
        url: String,
        title: String,
        width: Number,
        height: Number,
        xs: false,
        xs2x: false,
        sm: false,
        sm2x: false,
        md: false,
        md2x: false,
        lg: false,
        lg2x: false,
        mimeType: false,
        sizes: false,
        cover: {
            type: Boolean,
            default: false,
        },
        contain: {
            type: Boolean,
            default: false,
        },
        animate: {
            type: Boolean,
            default: true,
        }
    },
    data: () => ({
        vars: {
            perc: 0,
        }
    }),
    computed: {
        srcset() {
            return typeof this.xs !== 'undefined'
        },
        className() {
            let classname = 'o-asset'

            if(this.cover) {
                classname += ' o-asset--cover'
            }

            if(this.contain) {
                classname += ' o-asset--contain'
            }

            if(this.animate) {
                classname += ' o-asset--animate'
            }

            return classname
        },
        styles() {
            let styles = `--ratio: ${this.ratio};`

            if (this.animate) {
                styles += ` --perc: ${this.vars.perc};`
            }

            return styles
        },
        bgStyles() {
            let styles =` --img: url('${this.url}');`

            if (this.srcset) {
                styles += ` --img-xs: url('${this.xs}'); --img-xs2x: url('${this.xs2x}'); --img-sm: url('${this.sm}'); --img-sm2x: url('${this.sm2x}'); --img-md: url('${this.md}'); --img-lg: url('${this.lg}');`
            }

            return styles
        },
        ratio() {
            return (this.width/this.height).toFixed(3)
        }
    },
    methods: {
        parallax(e) {
            gsap.to(this.vars, .1, {
                perc: Math.max((e.percent.top - .5), 0)
            })
        },
    }
};

</script>

<style lang="scss">

.o-asset {
    border-radius: $border-radius;
    overflow: hidden;

    &--cover,
    &--contain {
        width: 100%;
        height: 100%;
    }

    &--cover .o-asset__bg {
        background-size: cover;
    }

    &--contain .o-asset__bg {
        background-size: contain;
    }

    &--animate {
        overflow: hidden;

        .o-asset__bg,
        .o-asset__img {
            transform: scale(calc(1 + var(--perc)/4));
            transform-origin: 50% 0;
            will-change: transform;
        }
    }

    // Fix border radius + overflow + transform bug
    .is-safari & {
        -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
}

.o-asset__picture {
    display: block;
    width: 100%;
}

.o-asset__img {
    @include img;
}

.o-asset__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: var(--img-xs, var(--img));
    background-position: 50% 50%;

    @media #{md("xs")} {
        background-image: var(--img-sm, var(--img));
    }

    @media #{md("sm")} {
        background-image: var(--img-md, var(--img));
    }

    @media #{md("md")} {
        background-image: var(--img-lg, var(--img));
    }

    // Retina displays
    @media (max-width: 768px) and (min-resolution: 192dpi) {
        background-image: var(--img-sm2x, var(--img));
    }

    @media (max-width: 480px) and (min-resolution: 192dpi) {
        background-image: var(--img-xs2x, var(--img));
    }
}

</style>
