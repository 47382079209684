import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'src/store/index'

import view from './directives/view' // eslint-disable-line
import { i18n } from 'src/i18n'
import 'src/filters'

import VueGtm from 'vue-gtm';
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'

// Var
Vue.config.productionTip = false

// Meta descriptions
Vue.use(VueMeta, {})

// Production
const isProd = process.env.NODE_ENV === 'production';

// GTM
if ( isProd && process.env.VUE_APP_GTM ) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM,
        queryParams: {
            gtm_auth: process.env.VUE_APP_GTM_AUTH,
            gtm_cookies_win:'x'
        },
        defer: true,
        enabled: true,
        debug: false,
        vueRouter: router,
        loadScript: true,
        // ignoredViews: [''],
        // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}

// Analytics
// Vue.use(VueAnalytics, {
//     id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
//     router,
//     debug: {
//         enabled: !isProd,
//         sendHitTask: isProd,
//     },
// });

// Google Map
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY
    }
})

// Initial load
store.dispatch('global/initLoad')

// Vue instance
new Vue({
    el: '#app',
    router,
    i18n,
    store,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
