/*
** GraphQL Configuration and variables
*/

export const api = '/api'

export const fetchApi = (query) => {
    // console.log('fetchApi:', query)

    return fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query })
        })
        .then(r => {
            // console.log('fetchApi response:', r)
            return r.json()
        })
        .then(r => {
            // console.log('fetchApi json:', r)
            return r.data;
        })
        .catch(error => {
            console.log('fetchApi error:', error)
            return undefined
        })
}
