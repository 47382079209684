import Vue from 'vue';
import VueRouter from 'vue-router'
import store from 'src/store'
import { defaultLocal, currentLocal } from 'src/i18n'

const Home = () => import(/* webpackChunkName: "home" */ 'templates/views/Home');
const Employment = () => import(/* webpackChunkName: "page" */ 'templates/views/Employment');
const Resources = () => import(/* webpackChunkName: "page" */ 'templates/views/Resources');
const Offers = () => import(/* webpackChunkName: "offers" */ 'templates/views/Offers');
const About = () => import(/* webpackChunkName: "page" */ 'templates/views/About');
const Contact = () => import(/* webpackChunkName: "contact" */ 'templates/views/Contact');
const Training = () => import(/* webpackChunkName: "training" */ 'templates/views/Training');
const Region = () => import(/* webpackChunkName: "page" */ 'templates/views/Region');
const Page = () => import(/* webpackChunkName: "page" */ 'templates/views/Page');
const Blog = () => import(/* webpackChunkName: "blog" */ 'templates/views/Blog');
const Article = () => import(/* webpackChunkName: "blog" */ 'templates/views/Article');

// Use plugin
Vue.use(VueRouter)

// Translated route paths
const paths = {
    employment: {
        fr: '/trouver-un-emploi',
        en: '/find-a-job',
    },
    resources: {
        fr: '/trouver-une-ressource',
        en: '/find-a-resource',
    },
    offers: {
        fr: '/offres',
        en: '/offers',
    },
    about: {
        fr: '/a-propos',
        en: '/about',
    },
    contact: {
        fr: '/contact',
        en: '/contact',
    },
    training: {
        fr: '/formations',
        en: '/trainings',
    },
    region: {
        fr: '/territoires/:slug',
        en: '/territories/:slug',
    },
    pages: {
        fr: '/pages/:slug',
        en: '/pages/:slug',
    },
    blog: {
        fr: '/blogue',
        en: '/blog',
    },
    articles: {
        fr: '/blogue/:slug',
        en: '/blog/:slug',
    }
}

// Routes
const routes = [
    {
        name: 'home',
        title: 'Homepage',
        path: '/',
        component: Home,
        meta: {
            section: 'home',
            type: 'single',
        }
    },
    {
        name: 'employment',
        title: 'Employment',
        path: paths.employment[currentLocal],
        component: Employment,
        meta: {
            section: 'employment',
            type: 'single',
            paths: paths.employment,
        }
    },
    {
        name: 'resources',
        title: 'resources',
        path: paths.resources[currentLocal],
        component: Resources,
        meta: {
            section: 'resources',
            type: 'single',
        }
    },
    {
        name: 'offers',
        title: 'offers',
        path: paths.offers[currentLocal],
        component: Offers,
        meta: {
            section: 'offers',
            type: 'single',
        }
    },
    {
        name: 'about',
        title: 'about',
        path: paths.about[currentLocal],
        component: About,
        meta: {
            section: 'about',
            type: 'single',
        }
    },
    {
        name: 'contact',
        title: 'contact',
        path: paths.contact[currentLocal],
        component: Contact,
        meta: {
            section: 'contact',
            type: 'single',
        }
    },
    {
        name: 'training',
        title: 'training',
        path: paths.training[currentLocal],
        component: Training,
        meta: {
            section: 'training',
            type: 'single',
        }
    },
    {
        name: 'region',
        title: 'Region',
        path: paths.region[currentLocal],
        component: Region,
        meta: {
            section: 'region',
            type: 'channel',
        }
    },
    {
        name: 'pages',
        title: 'Pages',
        path: paths.pages[currentLocal],
        component: Page,
        meta: {
            section: 'pages',
            type: 'channel',
        }
    },
    {
        name: 'blog',
        title: 'blog',
        path: paths.blog[currentLocal],
        component: Blog,
        meta: {
            section: 'blog',
            type: 'single',
        }
    },
    {
        name: 'articles',
        title: 'Articles',
        path: paths.articles[currentLocal],
        component: Article,
        meta: {
            section: 'articles',
            type: 'channel',
        }
    },
    {
        path: '*',
        redirect: '/'
    }
]

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: currentLocal === defaultLocal ? '/' : `/${currentLocal}`,
    routes,
    linkActiveClass: 'is-active',
})

// Before each route load
router.beforeEach((to, from, next) => {

    // Start load
    store.dispatch('loader/startLoad');

    // Load page
    store.dispatch('global/loadContent', to)

    // Loader cover duration
    setTimeout(() => {
        store.dispatch('global/updatePage', false)
        next()
    }, 200);
})

router.afterEach(() => {

    // End load
    store.dispatch('loader/endLoad');
})

// Link resolver
const linkResolver = (handle) => {

    const route = routes.find(route => route.meta.section === handle)

    if(route) {
        return route.path
    } else {
        return '/'
    }
}


// Export
export default router
export { paths, router, routes, linkResolver }
