import { fetchApi } from 'src/graphql/config';
import queries from 'src/graphql/queries';
import { router, linkResolver } from 'src/router';

/*
** Global
*/

// State
const state = {
    nav: [],
    sendInBlue: {},
    regions: [],
    subRegions: [],
    trainings: [],
    footer: {},
    currentPage: {},
    currentPageName: null,
};

// Getters
const getters = {
    getSubRegions: state => state.subRegions,
    getCurrentPage: state => state.currentPage,
    getCurrentPageName: state => state.currentPageName,
};

// Actions
const actions = {
    // Initial load for navigation, globals, etc...
    initLoad(store) {

        // Start load
        store.dispatch('loader/startLoad', null, {root: true});

        return Promise.all([
            store.dispatch('loadInitial')
        ]).then(() => {

            // End load
            store.dispatch('loader/endLoad', null, {root: true});
        }).catch(e => {
            console.error(e) // eslint-disable-line
        })
    },
    loadInitial(store) {

        return new Promise((resolve, reject) => {

            fetchApi(queries.initial)
                .then(data => {

                    // NAV
                    if (data.nav) {

                        // Format nav and add route path to each nav item
                        const nav = data.nav.navNodes;

                        nav.forEach(item => {
                            item.entry = item.entry[0];
                            item.path = linkResolver(item.entry.sectionHandle);
                        });

                        store.commit('setNav', nav);
                    }

                    // SendInBlue
                    if (data.sendInBlue) {
                        store.commit('setSendInBlue', data.sendInBlue);
                    }

                    // Footer
                    if (data.footer) {
                        store.commit('setFooter', data.footer);
                    }

                    // Regions
                    if (data.regions) {
                        const entries = data.regions

                        // Set main regions
                        store.commit('setRegions', entries);

                        // Generate sub regions list
                        let subRegions = []
                        state.regions.forEach(region => {
                            subRegions = [...subRegions, ...region.selectedSubRegions]
                        })

                        // Remove duplicates
                        subRegions = Array.from(new Set(subRegions.map(a => a.id)))
                            .map(id => subRegions.find(a => a.id === id))

                        // Order
                        subRegions.sort((a, b) => a.title > b.title ? 1 : -1)

                        // Set sub regions
                        store.commit('setSubRegions', subRegions);
                    }

                    // Footer
                    if (data.trainingsList) {
                        store.commit('setTrainingsList', data.trainingsList.trainingsList);
                    }

                    resolve(data)
                })
                .catch(e => {
                    reject({
                        code: 400,
                        message: 'Something went wrong'
                    })
                    console.error(e) // eslint-disable-line
                })
        })
    },
    loadContent(store, to) {

        return new Promise((resolve) => {

            // Timer in seconds
            const timer = new Date

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const type = to.meta.type;

            // For channel entries, load specific page with id
            if (type === 'channel') {
                const slug = to.params.slug;
                fetchContent = store.dispatch('pages/loadEntry', { handle: handle, slug: slug }, {root: true});

            // For single entries, load page with handle
            } else {
                fetchContent = store.dispatch('pages/loadSingle', handle, {root: true});
            }

            fetchContent
            .then((page) => {
                // Duration in ms of the fetchContent + 200ms for the loader cover delay (see loader component) + 10ms for latency
                let delay = (timer - new Date) + 210
                delay = delay < 0 ? 0 : delay

                setTimeout(() => {
                    store.commit('setPage', page);
                    resolve(page);

                    // Timeout to let template to render data
                    setTimeout(() => {
                        store.dispatch('loader/endLoad', null, {root: true});
                    }, 100);

                }, delay);
            })
            .catch(e => {
                console.error(e) // eslint-disable-line
                if (e.code && e.code == 404) {
                    router.push('/');
                }
                store.dispatch('loader/endLoad', null, {root: true})
            })

        });
    },
    updatePage(store, page) {
        store.commit('setPage', page)
    },
    updatePageName(store, name) {
        store.commit('setPageName', name)
    },
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.nav = nav;
    },
    setSendInBlue(state, sendInBlue) {
        state.sendInBlue = sendInBlue;
    },
    setFooter(state, footer) {
        state.footer = footer;
    },
    setRegions(state, region) {
        state.regions = region
    },
    setSubRegions(state, subRegions) {
        state.subRegions = subRegions
    },
    setTrainingsList(state, trainings) {
        state.trainings = trainings
    },
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
