<template>
    <div
        id="app"
        class="l-wrapper"
    >
        <app-base />
    </div>
</template>

<script>

import 'assets/scss/styles.scss';

import Variables from 'src/mixins/variables';

import AppBase from 'layout/AppBase';

export default {
    name: 'app',
    components: {
        AppBase,
    },
    mixins: [ Variables ],
    metaInfo() {
        return {
            titleTemplate: `%s`,
            meta: [

                // Author
                {
                    name: 'author',
                    content: 'MamboMambo'
                },

                // Facebook
                {
                    property: 'og:title',
                    content: ''
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: process.env.VUE_APP_SITE_URL
                },
                {
                    property: 'og:image',
                    content: `${process.env.VUE_APP_SITE_URL}/og/facebook.jpg`
                },
                {
                    property: 'og:image:type',
                    content: 'image/jpeg'
                },
                {
                    property: 'og:site_name',
                    content: ''
                },
                {
                    property: 'og:description',
                    content: ''
                },

                // Twitter
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:site',
                    content: '@mvuestarter'
                },
                {
                    name: 'twitter:creator',
                    content: '@mambomambo'
                },
                {
                    name: 'twitter:image',
                    content: `${process.env.VUE_APP_SITE_URL}/og/twitter.jpg`
                },
                {
                    name: 'twitter:image:al',
                    content: 'Image alt'
                },
            ]
        }
    },
    created() {
        // Browser and OS classes
        document.documentElement.classList.add(`is-${this.browser.os.replace(' ', '-').toLowerCase()}`)
        document.documentElement.classList.add(`is-${this.browser.name}`)

        this.$store.dispatch('loader/startLoad')
    },
    mounted() {
        this.$store.dispatch('loader/endLoad')
    },
}
</script>

<style lang="scss">
</style>
